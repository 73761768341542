import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PagedEntities } from '@shared/models/paged-entities';
import { Lookup } from '@shared/state/lookup/lookup';
import { PageRequest } from '@shared/table/page';

import { Class } from '../class-state/class';
import { ClassAppearance } from '../class-state/class-appearance';
import { ClassCategory } from '../class-state/class-category';
import { ClassCategoryImage } from '../class-state/class-category-image';
import { ClassSearchQuery } from './classes.service';

@Injectable({ providedIn: 'root' })
export class ClassDataService {
    constructor(private httpClient: HttpClient) {}

    getClasses(
        request: PageRequest<Class>,
        query: ClassSearchQuery
    ): Observable<PagedEntities<Class>> {
        const params = new HttpParams({
            fromObject: {
                className: query.className ?? '',
                searchText: query.searchText ?? '',
                facilitators: query.facilitators?.join(',') ?? '',
                classCategories: query.programCategories?.join(',') ?? '',
                classGroups: query.programGroups?.join(',') ?? '',
                classEditingGroups: query.programEditingGroups?.join(',') ?? '',
                recurrenceStatus: query.recurrenceStatus?.join(',') ?? '',
                languages: query.programLanguages?.join(',') ?? '',
                isOrganizationLevel: query.isOrganizationLevel ?? '',
                remainingSessions: query.remainingSessions ?? '',
                classRecurrence: query.classRecurrence ?? '',
                isRecurring: query.isRecurring ?? '',
                toDate: query.sessionDate?.end?.toApiStringFormat() ?? '',
                fromDate: query.sessionDate?.start?.toApiStringFormat() ?? '',
                createdBy: query.createdBy ?? '',
                createdDate: query.createdDate?.toApiStringFormat() ?? '',
                page: request.page.toString(),
                pageSize: request.size.toString(),
                sortBy: request.sort?.property ?? '',
                sortDirection: request.sort?.order ?? '',
                isShared: query.isShared ?? '',
            },
        });

        return this.httpClient.get<PagedEntities<Class>>(
            environment.apiUrl + '/vsc/classes',
            {
                params,
            }
        );
    }

    getAllClasses(): Observable<PagedEntities<Class>> {
        const params = new HttpParams({
            fromObject: {
                name: '',
                page: '0',
                pageSize: '1000',
                sortBy: '',
                sortDirection: '',
            },
        });

        return this.httpClient.get<PagedEntities<Class>>(
            environment.apiUrl + '/vsc/classes',
            {
                params,
            }
        );
    }

    getClassCreators(): Observable<Array<Lookup>> {
        return this.httpClient.get<Array<Lookup>>(
            environment.apiUrl + `/vsc/classes/creators`
        );
    }

    getById(id: string): Observable<Class> {
        return this.httpClient.get<Class>(
            environment.apiUrl + `/vsc/classes/${id}`
        );
    }

    post(classItem: Class): Observable<Class> {
        return this.httpClient.post<Class>(
            environment.apiUrl + `/ag/classes`,
            classItem
        );
    }

    put(classItem: Class): Observable<Class> {
        return this.httpClient.put<Class>(
            environment.apiUrl + `/ag/classes`,
            classItem
        );
    }
    updateClassGroups(classId: string, groups: string[]): Observable<Class> {
        return this.httpClient.put<Class>(
            environment.apiUrl + `/vsc/classes/${classId}/class-groups`,
            groups
        );
    }

    delete(id: string): Observable<any> {
        return this.httpClient.delete(
            environment.apiUrl + `/vsc/classes/${id}`
        );
    }

    getClassCategories(): Observable<Array<ClassCategory>> {
        return this.httpClient.get<Array<ClassCategory>>(
            environment.apiUrl + `/vsc/classes/categories`
        );
    }

    getCategoryImages(
        classCategoryId: string
    ): Observable<ClassCategoryImage[]> {
        return this.httpClient.get<ClassCategoryImage[]>(
            environment.apiUrl +
                `/vsc/classes/categories/${classCategoryId}/images`
        );
    }
    getDefaultCategoryImages(): Observable<ClassCategoryImage[]> {
        return this.httpClient.get<ClassCategoryImage[]>(
            environment.apiUrl + `/vsc/classes/categories/default-images`
        );
    }

    updateClassAppearance(
        classId: string,
        classAppearance: ClassAppearance
    ): Observable<any> {
        return this.httpClient.patch(
            environment.apiUrl + `/vsc/classes/${classId}/appearance`,
            classAppearance
        );
    }

    validateRecurrenceEndDate(
        startDate: string,
        recurrence: string,
        endDate: string
    ): Observable<boolean> {
        const encodedrRecurrence = encodeURIComponent(recurrence);
        return this.httpClient.get<boolean>(
            environment.apiUrl +
                `/vsc/classes/recurrence/validate-enddate?startDate=${startDate}&recurrence=${encodedrRecurrence}&endDate=${endDate}`
        );
    }
}
